import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import LocationView from "../../components/location-view";

function Location(props) {
  const { strapiLocations } = props.data;

  return (
    <>
      <Layout>
        <LocationView location={strapiLocations} />
      </Layout>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    strapiLocations(slug: { eq: $slug }) {
      lat
      long
      ort
      slug
      info
      id
      typ
      googleMapsLink
      map {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default Location;
