import * as React from "react";
import Markdown from "react-markdown";
import Container from "./container";
import { GatsbyImage } from "gatsby-plugin-image"

function LocationView({ location }) {
  //const llat = location.lat;
  //const llong = location.long;  
  return (
    <>
      <Container>
        <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-12">
          <div className="relative bg-white">
            <div className="relative h-72 bg-white sm:h-72 md:absolute md:left-0 md:h-full md:w-4/6">
              <GatsbyImage
                image={location.map.localFile.childImageSharp.gatsbyImageData}
                className="w-full h-full object-cover"
                alt="Rüebenumschlag Mittelland"
              />
            </div>
            <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
              <div className="md:ml-auto md:w-2/6 md:pl-12">
                <h2 className="text-base font-semibold uppercase tracking-wider text-gray-700">
                  {location.typ}
                </h2>
                <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
                  {location.ort}
                </p>
                <p className="mt-3 text-lg text-gray-700">
                  <Markdown source={location.info} escapeHtml={false} />
                </p>
                {location.googleMapsLink && (
                  <div className="mt-6">
                    <a
                      href={location.googleMapsLink}
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r bg-gray-600 hover:bg-gray-900"
                    >
                      Google Maps öffnen
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="mt-6 border-t border-gray-200 pt-8"></div>
      </Container>
    </>
  );
}

export default LocationView;
